[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0.125em;
font-size: 50px;

@media #{$large-up} {
font-size: 80px;

}
}
h2 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h3 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 35px;

}
}
h4 {
font-family: 'Poppins';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0.0625em;
font-size: 22px;

@media #{$medium-up} {
font-size: 25px;

}
@media #{$large-up} {
font-size: 30px;

}
}
h5 {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
summary {
font-family: 'Poppins';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0.0625em;
font-size: 22px;

@media #{$medium-up} {
font-size: 25px;

}
@media #{$large-up} {
font-size: 30px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$large-up} {
font-size: 18px;

}
}
p { padding-bottom: 1em; }
/* Logo panel:2 */
.MES2 {
padding: 10px;

@media #{$large-up} {
padding: 20px 50px;

}
 }
.MES2 {
padding: 10px;

@media #{$large-up} {
padding: 20px 50px;

}
 }
/* Panel background:3 */
.MES3 {
background-color: var(--clr-2861);
& > .underlay, & > .inner-overlay { opacity: 0.45 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://arielmotors.com.au/img/44/17');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
 }
.MES3 {
background-color: var(--clr-2861);
& > .underlay, & > .inner-overlay { opacity: 0.45 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://arielmotors.com.au/img/44/17');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Carousel Style:4 */
.MES4 {
& .slider-arrow {color: var(--clr-2862);
font-size: 40px;
@media #{$medium-up} {
font-size: 50px;
};
@media #{$large-up} {
font-size: 70px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 3px;
;border-color: 2;
border-radius: 50%;
border-style:solid;
border-width:1px;
@media #{$medium-up} {
border-width: 1px;
};
@media #{$large-up} {
border-width: 1px;};
&:hover{border-color: 2;}
background-color: 3;
background-clip: padding-box;
&:hover {background-color: 6;}
width:12px;
height:12px;
@media #{$medium-up} {
width:12px;
height:12px;
};
@media #{$large-up} {
width:12px;
height:12px;
};
&:hover{background-color: 6;}
}}
 }
/* Responsive Header:5 */
.MES5 {
background-color: var(--clr-2863);
 }
.MES5 {
background-color: var(--clr-2863);
 }
/* Main Menu:7 */
nav.me-Menu.MES7 {
& .menu-item.MEC7, & .menu-item.MEC7 > div.MEC7{ & > a.MEC7{color: var(--clr-2862);
font-size: 16px;
@media #{$large-up} {
font-size: 19.2px;
}text-transform: uppercase;
}
 &:hover > a.MEC7{color: var(--clr-2864);
}
 }
&.horizontal > .menu-item.MEC7 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC7 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC7 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC7 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px 30px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Left Menu:8 */
nav.me-Menu.MES8 {
& .menu-item.MEC8, & .menu-item.MEC8 > div.MEC8{ & > a.MEC8{color: var(--clr-2862);
text-transform: uppercase;
}
 &:hover > a.MEC8{color: var(--clr-2862);
}
 }
&.horizontal > .menu-item.MEC8 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC8 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC8 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC8 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Responsive Menu:9 */
nav.responsive-menu {
.menu-item.MEC9{background-color: var(--clr-2863);}
& .menu-item.MEC9, & .menu-item.MEC9 > div.menu-item-wrap{ & > a.MEC9, & > i{color: #ffffff;
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC9 { border:0;
border-color: #ffffff;border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px 15px;}

& .sub-menu{ .sub-menu {}}

 }
/* Form Button:10 */
.MES10 {
background-color: var(--clr-2865);
&:hover {background-color: var(--clr-2864);}
color: #ffffff;
&:hover { color: #ffffff;}
padding: 10px 15px;

 }
/* Form Button large:11 */
.MES11 {
background-color: var(--clr-2865);
&:hover {background-color: var(--clr-2864);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 19.2px;
@media #{$large-up} {
font-size: 24px;
};
padding: 20px;

 }
/* Copywrite:12 */
.MES12 {
font-size: 11.2px;
@media #{$large-up} {
font-size: 12.6px;
};
 }
.MES12 {
font-size: 11.2px;
@media #{$large-up} {
font-size: 12.6px;
};
 }
cite.MEC12{
font-size: 11.2px;
@media #{$large-up} {
font-size: 12.6px;
};
}
